<template>
  <div class="nav-bar">
    <div class="nav-list">
      <div v-if="hasProducts('Data')" class="nav-wrapper">
        <div
          class="nav-item d-flex flex-column"
          :class="isItemActive('/data') ? 'active-item' : ''"
          @click="onItemClick('/data')"
        >
          <div class="d-flex align-items-center justify-content-center">
            <!-- TODO: Handle the database icon in a better way -->
            <img
              v-if="isItemActive('/data')"
              src="/images/icons/database.svg"
            />
            <img v-else src="/images/icons/database-white.svg" />
          </div>
          <div
            class="d-flex align-items-center justify-content-center text-center"
          >
            Data
          </div>
        </div>
      </div>
      <div v-if="hasProducts('Sample-dashboards')" class="nav-wrapper">
        <div
          class="nav-item d-flex flex-column"
          :class="isItemActive('/sample-dashboards') ? 'active-item' : ''"
          @click="onItemClick('/sample-dashboards')"
        >
          <div class="d-flex align-items-center justify-content-center">
            <i class="bi bi-bar-chart"></i>
          </div>
          <div
            class="d-flex align-items-center justify-content-center text-center"
          >
            Sample Dashboards
          </div>
        </div>
      </div>
      <div v-if="hasProducts('Guides')" class="nav-wrapper">
        <div
          class="nav-item d-flex flex-column"
          :class="isItemActive('/guides') ? 'active-item' : ''"
          @click="onItemClick('/guides')"
        >
          <div class="d-flex align-items-center justify-content-center">
            <i class="bi bi-folder2"></i>
          </div>
          <div
            class="d-flex align-items-center justify-content-center text-center"
          >
            Guides
          </div>
        </div>
      </div>
      <div class="nav-wrapper">
        <div
          class="nav-item d-flex flex-column"
          :class="isItemActive('/release-notes') ? 'active-item' : ''"
          @click="onItemClick('/release-notes')"
        >
          <div class="d-flex align-items-center justify-content-center">
            <i class="bi bi-card-list"></i>
          </div>
          <div
            class="d-flex align-items-center justify-content-center text-center"
          >
            Release Notes
          </div>
        </div>
      </div>
      <div v-if="hasAdminAppsAccess()" class="nav-wrapper">
        <div
          class="nav-item d-flex flex-column"
          :class="isItemActive('/admin') ? 'active-item' : ''"
          @click="onItemClick('/admin')"
        >
          <div class="d-flex align-items-center justify-content-center">
            <i class="bi bi-grid"></i>
          </div>
          <div
            class="d-flex align-items-center justify-content-center text-center"
          >
            Admin
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  // Store
  const appStore = useAppStore()

  // Properties
  const activeRoute: Ref<string> = ref('Data')
  const routePathMap: Record<string, string> = {
    guides: '/guides',
    schema: '/data',
    'sample-dashboards': '/sample-dashboards',
    'release-notes': '/release-notes',
    admin: '/admin',
    '/': '/data',
  }

  // Emitter composbles
  const emitter = useEmitter()

  // Hooks
  onMounted(() => {
    setActiveRoutePath()
    emitter.on('setActiveRoutePath', () => {
      setActiveRoutePath()
    })
  })

  // Methods and Event handlers
  const onItemClick = async (value: string) => {
    activeRoute.value = value
    await navigateTo(value)
  }

  const isItemActive = (routePath: string) => {
    return routePath === activeRoute.value
  }

  const hasProducts = (category: string) => {
    /** Hide the nav-item(option) if the the catagory doesn't have any items to show on the right pane */
    if (category === 'Sample-dashboards') {
      return appStore.products.SampleDashboards.length > 0
    }
    return appStore.products[category].length > 0
  }

  const hasRouteHash = (category: string) => {
    const router = useRouter()
    return productsNameByCategory(category).includes(
      router.currentRoute.value.hash,
    )
  }

  const hasAdminAppsAccess = () => {
    return appStore.user.is_compile_user && appStore.products.Admin.length > 0
  }

  const productsNameByCategory = (category: string) => {
    if (
      appStore.user.entitled_products.filter(
        (product) => product.category === category,
      ).length > 0
    ) {
      return appStore.user.entitled_products.map((product) =>
        product.category === category ? new URL(product.primary_url).hash : '',
      )
    } else {
      return []
    }
  }

  // To set the active nav item for the current route
  const setActiveRoutePath = () => {
    const router = useRouter()
    /** If user is directly landing on any specific url without any user iteraction, OR
     * If user routes via notifications.
     * We need to set the nav-item(option) active class.
     * Deciding it based on routepath  */
    if (router.currentRoute.value.path.includes('viz')) {
      if (hasRouteHash('Guides')) {
        activeRoute.value = '/guides'
        return
      }
      if (hasRouteHash('Sample Dashboards')) {
        activeRoute.value = '/sample-dashboards'
      }
    } else {
      const pathMatch: any = Object.keys(routePathMap).find((key) =>
        router.currentRoute.value.path.includes(key),
      )
      activeRoute.value = pathMatch
        ? routePathMap[pathMatch as routePathMap]
        : router.currentRoute.value.path
    }
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/_color-palette.scss';
  .nav-bar {
    background-color: $dark-blue;
    height: calc(100vh - 3.875rem);
    border: none;
    border-right: 1px solid $border-gray;
    width: 7rem;
  }
  .nav-wrapper {
    margin: 0.25rem 0.5rem 1rem 0.5rem !important;
  }
  .nav-item {
    padding-block: 2.5rem;
    padding-inline: 0.25rem;
    border-radius: 0.5rem;
    cursor: pointer;
    @include font-style(11.2px, 500, $white);
    height: 68px;
    justify-content: center;
    margin-block: 0.5rem;
    & i {
      font-size: 20px;
    }
    &:hover {
      background: $compile-hover-blue; //$white;
      color: $white; //$dark-blue;
    }
  }
  .active-item {
    background: $white !important;
    color: $dark-blue !important;
  }
</style>
